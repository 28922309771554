import React from "react";
import { Redirect, Route } from "react-router-dom";
import auth from "./Auth/auth";

function AuthMiddleware({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (auth.isAuthenticated()) {
          return <Component {...routeProps} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
}

export default AuthMiddleware;
