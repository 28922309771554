import React from "react";
import "./sidebar.css";
import TwitterIcon from "@material-ui/icons/Twitter";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import SidebarOption from "./SidebarOption";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import auth from "./components/Auth/auth";
import { Link } from "react-router-dom";
function Sidebar(props) {
  const { enqueueSnackbar } = useSnackbar();
  const handleLogOut = () => {
    auth.logout(() => {
      props.history.push("/login");
    });
    enqueueSnackbar("Successfully Logged Out", {
      variant: "success",
    });
  };

  return (
    <div className='sidebar'>
      <Link to='/home'>
        <TwitterIcon className='sidebar__twitterIcon' />
      </Link>
      <SidebarOption text='Home' to='/home' Icon={HomeIcon} />
      <SidebarOption text='Profile' to='/profile' Icon={PermIdentityIcon} />
      <SidebarOption text='Explore' to='/explore' Icon={SearchIcon} />
      <SidebarOption
        text='Notifications'
        to='/notifications'
        Icon={NotificationsNoneIcon}
      />
      <SidebarOption text='Messages' to='/messages' Icon={MailOutlineIcon} />
      <SidebarOption
        text='Bookmarks'
        to='/bookmarks'
        Icon={BookmarkBorderIcon}
      />
      <SidebarOption text='Lists' to='/lists' Icon={ListAltIcon} />
      <SidebarOption text='More' to='/more' Icon={MoreHorizIcon} />

      <Button
        variant='outlined'
        className='sidebar__tweet'
        fullWidth
        onClick={handleLogOut}>
        Log Out
      </Button>
      {/* Twitter icon */}
      {/*multiple  Sidebar options*/}
      {/* Twitter button */}
    </div>
  );
}

export default Sidebar;
