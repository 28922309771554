import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
//import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import axios from "axios";
import { Link } from "react-router-dom";
import { API_URL } from "../config";
import errorService from "../services/Errors";
import { CircularProgress } from "@material-ui/core";

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {"Copyright © "}
      {/* <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."} */}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Register(props) {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");

  const [validationErrors, setValidationErrors] = useState(errorService);
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setValidationErrors(errorService.reset());
    setLoading(true);
    axios
      .post(`${API_URL}/register`, {
        name: name,
        email: email,
        password: password,
        password_confirmation: password_confirmation,
        phone: phone,
      })
      .then((response) => {
        if (response.status === 201) {
          // props.history.push("/login");
          setLoading(false);
          props.history.push({
            pathname: "/login",
            state: {
              message: "Registration Successful. Please login to continue!",
            },
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        setValidationErrors(errorService.setErrors(error.response));
      });
  };

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Sign up
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                id='name'
                label='Full Name'
                name='name'
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoComplete='name'
              />
              {validationErrors && (
                <span className='text-red'>{errorService.getKey("name")}</span>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete='email'
              />
              {validationErrors && (
                <span className='text-red'>{errorService.getKey("email")}</span>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                id='phone'
                label='Phone'
                name='phone'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                autoComplete='false'
              />
              {validationErrors && (
                <span className='text-red'>{errorService.getKey("phone")}</span>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id='password'
                autoComplete='current-password'
              />
              {validationErrors && (
                <span className='text-red'>
                  {errorService.getKey("password")}
                </span>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                name='password_confirmation'
                label='password_confirmation'
                type='password'
                value={password_confirmation}
                onChange={(e) => setPassword_confirmation(e.target.value)}
                id='password_confirmation'
                autoComplete='false'
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value='allowExtraEmails' color='primary' />}
                label='I want to receive inspiration, marketing promotions and updates via email.'
              />
            </Grid>
          </Grid>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
            disabled={loading}>
            {loading && <CircularProgress size={20} />}
            {loading && <span style={{ marginLeft: "5px" }}>Signin Up </span>}
            {!loading && <span> Sign Up</span>}
          </Button>
          <Grid container justify='flex-end'>
            <Grid item>
              <Link to='/login' variant='body2'>
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}
