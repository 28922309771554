import React, { useState, useEffect } from "react";
import "./tweetbox.css";
import { Avatar, Button } from "@material-ui/core";
import axios from "axios";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import { API_URL } from "./components/config";
import errorService from "./components/services/Errors";
import { useSnackbar } from "notistack";
import auth from "./components/Auth/auth";
function TweetBox(props) {
  const ref = React.useRef();
  const [tweetMessage, setTweetMessage] = useState("");
  const [tweetDetails, setTweetDetails] = useState("");
  const [category, setCategory] = useState(1);
  const [categoryList, setCategoryList] = useState([]);
  const [image, setImage] = useState("");
  const [error, setError] = useState(errorService);
  const { enqueueSnackbar } = useSnackbar();
  const headers = auth.getHeaders();
  useEffect(() => {
    axios
      .post(`${API_URL}/get-category-list`, {}, { headers })
      .then(function (response) {
        setCategoryList(response.data.data);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const sendTweet = (e) => {
    e.preventDefault();

    setError(errorService.reset());
    const formData = new FormData();

    formData.append("image[]", image);
    formData.append("title", tweetMessage);
    formData.append("details", tweetDetails);
    formData.append("category_id", category);

    axios
      .post(`${API_URL}/post/store`, formData, { headers })
      .then((response) => {
        //console.log(response);
        if (response.status === 200) {
          props.postAppend(response.data.data);
          enqueueSnackbar("Post Added Successfully!", {
            variant: "success",
          });
        }
      })
      .catch((error) => {
        // console.log(error);
        setError(errorService.setErrors(error.response));
      });

    setTweetMessage("");
    setTweetDetails("");
    ref.current.value = "";
  };
  return (
    <div className='tweetBox'>
      <form>
        <div className='tweetBox__input'>
          <Avatar src={auth.getProfileImg()}></Avatar>
          <input
            value={tweetMessage}
            onChange={(e) => setTweetMessage(e.target.value)}
            placeholder='Enter Post Title'
            type='text'
            className='tweet__title'
            required
          />
          {error && (
            <span className='text-red'>{errorService.getKey("title")}</span>
          )}
        </div>

        <div className='tweetBox__category'>
          <select
            className='category__select'
            value={category}
            onChange={(e) => setCategory(e.target.value)}>
            {categoryList.map((list, index) => {
              const { id, name } = list;
              return (
                <option key={index} value={id}>
                  {name}
                </option>
              );
            })}
          </select>
          <textarea
            value={tweetDetails}
            onChange={(e) => setTweetDetails(e.target.value)}
            className='tweetBox__details'
            placeholder='Post Details'
            type='text'
            required
          />
          {error && (
            <span className='text-red'>{errorService.getKey("details")}</span>
          )}
        </div>

        <div className='tweet__footer'>
          <div className='image__upload_icon'>
            <input
              type='file'
              id='file'
              ref={ref}
              className='upload__image'
              onChange={(e) => setImage(e.target.files[0])}
              style={{ display: "none" }}
            />

            <label htmlFor='file'>
              <InsertPhotoIcon className='upload__image' fontSize='large' />
            </label>
          </div>
          <Button
            type='submit'
            onClick={sendTweet}
            className='tweetBox__tweetButton'>
            Tweet
          </Button>
        </div>
      </form>
    </div>
  );
}

export default TweetBox;
