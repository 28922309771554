import React from 'react'

function TopHeader(props) {
    const {title} = props;
    return (
        <div>
            <h2>{title}</h2>
        
        </div>
    )
}

export default TopHeader
