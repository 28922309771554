import React, { useState } from "react";
import { Avatar } from "@material-ui/core";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import RepeatIcon from "@material-ui/icons/Repeat";

import PublishIcon from "@material-ui/icons/Publish";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Comment from "./Comment";
import Lightbox from "react-image-lightbox";

import Follow from "./Follow";
function IndividualPost({
  id,
  username,
  title,
  details,
  image,
  avatar,
  created_at,
  comments,
  comments_count,
  follow,
  followers_count,
}) {
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [followersCount, setFollowersCount] = useState(followers_count);
  const handleFollowersCount = (value) => {
    if (value === "subtract") {
      setFollowersCount(followersCount - 1);
    } else {
      setFollowersCount(followersCount + 1);
    }
  };
  return (
    <>
      <div className='detail_main'>
        <div className='detail'>
          <div className='detail__header'>
            <div className='detail__avatar'>
              <Avatar
                src={
                  avatar
                    ? avatar
                    : " https://res.cloudinary.com/surazz501/image/upload/v1613750188/eov702nqhrih2bezb3hv.png"
                }
              />
            </div>

            <div className='detail__headerText'>
              <h3 className='detail__h3'>
                {username}
                <span className='detail__headerSpecial'>
                  <VerifiedUserIcon className='detail__badge' />
                </span>
              </h3>
              <span className='more-horizon'>
                <MoreHorizIcon />
              </span>
            </div>
          </div>

          <div className='detail__body'>
            <div className='detail__description'>
              <p>{title}</p>
              <p>{details}</p>
            </div>
            <div className='detail_image__container'>
              {image?.map((image, index) => {
                const { path } = image;

                return (
                  <>
                    {isImageOpen && (
                      <Lightbox
                        mainSrc={path}
                        onCloseRequest={() => setIsImageOpen(false)}
                      />
                    )}
                    <img
                      onClick={() => setIsImageOpen(true)}
                      key={index}
                      className='detail__image'
                      alt=''
                      src={path}
                    />
                  </>
                );
              })}
            </div>

            <div className='detail__timestamp'>{created_at}</div>
            <div className='detail__timestamp'>
              <span className='comment__count'>{comments_count}</span> comments{" "}
              <span className='followers_count'>{followersCount}</span>{" "}
              followers
            </div>

            <div className='detail__footer'>
              <ChatBubbleOutlineIcon fontSize='small' />
              <RepeatIcon fontSize='small' />
              <Follow
                follow={follow}
                id={id}
                followers_count={followers_count}
                updateFollowersCount={(value) => handleFollowersCount(value)}
              />
              <PublishIcon fontSize='small' />
            </div>
          </div>
        </div>
        <div className='comment-sections'>
          <div>
            {comments.map((comment_instance, index) => {
              const {
                id,
                parent_id,
                comment,
                replies,
                user_name,
                created_at,
                user_image,
              } = comment_instance;
              return (
                <>
                  <Comment
                    key={index}
                    parent_id={parent_id}
                    // reply={replies}
                    message={comment}
                    user={user_name}
                    date={created_at}
                    avatar={user_image}
                  />
                  {replies.map((reply, index) => {
                    return (
                      <div style={{ marginLeft: "10%" }}>
                        <Comment
                          key={reply.id}
                          parent_id={reply.parent_id}
                          message={reply.comment}
                          user={reply.user_name}
                          date={reply.created_at}
                          avatar={reply.user_image}
                        />
                      </div>
                    );
                  })}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default IndividualPost;
