import React, { useState, useEffect } from "react";
import "./profileCard.css";
import { Button, CircularProgress } from "@material-ui/core";
import auth from "./Auth/auth";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { API_URL } from "../components/config";
import axios from "axios";
import { useSnackbar } from "notistack";

function ProfileCard(props) {
  const [profileUrl, setProfileUrl] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const [overlay, setOverlay] = useState("");
  const headers = auth.getHeaders();
  const uploadProfile = (e) => {
    //console.log(e.target.files[0]);
    const formData = new FormData();
    formData.append("profile_img", e.target.files[0]);

    setOverlay(1);

    axios
      .post(
        `${API_URL}/post-profile-pic`,

        formData,

        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          auth.setProfileImg(response.data.data.profile_img);
          setProfileUrl(response.data.data.profile_img);

          enqueueSnackbar("Profile picture changed Successfully!", {
            variant: "success",
          });
          setOverlay(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    setProfileUrl(auth.getProfileImg());
  }, [profileUrl]);

  return (
    <>
      <div className='profile__card'></div>
      <div className='profile__details'>
        <div className='profile__img__wrapper'>
          <img alt='' className='profile__img' src={profileUrl} />
          <div
            className='image-overlay'
            // style={{ opacity: isLoading && overlay ? 1 : "none" }}>
            style={{ opacity: overlay ? 1 : "" }}>
            <div className='camera-img-wrapper'>
              <label htmlFor='camera-file' className='label'>
                {overlay && (
                  <CircularProgress
                    className='camera-img'
                    color='secondary'
                    size={20}
                  />
                )}
                {!overlay && <CameraAltIcon className='camera-img' />}
              </label>
              <input
                type='file'
                id='camera-file'
                className='d-none'
                onChange={(e) => uploadProfile(e)}
              />
            </div>
          </div>
        </div>
        {/* <Avatar src="https://pbs.twimg.com/profile_images/1208306503368658944/bloZFfIx_bigger.jpg" className="profile__avatar"></Avatar> */}
        <div className='edit__profile_wrapper'>
          <Button variant='outlined' className='edit__profile_btn'>
            Edit Profile
          </Button>
        </div>
      </div>
      <div className='profile__user_name'>
        <span>{props.username}</span>
      </div>
      <br />
    </>
  );
}

export default ProfileCard;
