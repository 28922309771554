import React from "react";
import { Avatar } from "@material-ui/core";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

function Comment({
  user,
  message,
  id,
  parent_id,
  //image,
  avatar,
  date,
  //slug,
}) {
  return (
    <div>
      <div className='post'>
        <div className='post__avatar'>
          <Avatar
            src={
              avatar
                ? avatar
                : " https://res.cloudinary.com/surazz501/image/upload/v1613750188/eov702nqhrih2bezb3hv.png"
            }
          />
        </div>

        <div className='post__body'>
          <div className='post__header'>
            <div className='post__headerText'>
              <h3>
                {user}
                <span className='post__headerSpecial'>
                  <VerifiedUserIcon className='post__badge' />
                </span>
              </h3>
              <span className='header__timestamp'>{date} </span>
            </div>
            <div className='comment__description'>
              <p>{message} </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Comment;
