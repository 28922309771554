import React from "react";
import "./feed.css";

import TopHeader from "./TopHeader";

function Feed(props) {
  
  return (
    <div className="feed">
     <div className="feed__header">
       <TopHeader title={props.headerTitle} />
      </div>
      {props.children}
     
    </div>
  );
}

export default Feed;
