import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
//import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
//import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import axios from "axios";
import auth from "./auth";
import { Redirect, Link } from "react-router-dom";
import AlertMessage from "../AlertMessage";
import { API_URL } from "../config";
import errorService from "../services/Errors";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validationErrors, setValidationErrors] = useState(errorService);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setValidationErrors(errorService.reset());

    axios
      .post(
        `${API_URL}/login`,
        {
          email: email,
          password: password,
        },
        {},
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        auth.login(() => {
          props.history.push("/home");
        }, response.data);
        setLoading(false);
        enqueueSnackbar("Successfully Logged In", {
          variant: "success",
        });
      })
      .catch(function (error) {
        setLoading(false);
        setValidationErrors(errorService.setErrors(error.response));
        // eslint-disable-next-line
        if (error.response.status == "401") {
          setError(
            "Incorrect Credentials . or Your Account may not be active, please contact admin!!"
          );
        }
      });
  };

  if (auth.isAuthenticated()) {
    return <Redirect to='/home' />;
  } else {
    return (
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Sign in
          </Typography>
          {props.location.state && (
            <AlertMessage
              type='success'
              message={props.location.state.message}
            />
          )}
          {error && <AlertMessage type='error' message={error} />}
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
              autoFocus
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            {validationErrors && (
              <span className='text-red'>{errorService.getKey("email")}</span>
            )}
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              autoComplete='current-password'
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            {/* {errors.password && <p className='has-error'>{errors.password}</p>} */}
            {validationErrors && (
              <span className='text-red'>
                {errorService.getKey("password")}
              </span>
            )}
            <br />
            <FormControlLabel
              control={<Checkbox value='remember' color='primary' />}
              label='Remember me'
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              disabled={loading}>
              {loading && <CircularProgress size={20} />}
              {loading && (
                <span style={{ marginLeft: "5px" }}>Signing In </span>
              )}
              {!loading && <span>Sign In</span>}
            </Button>
            <Grid container>
              <Grid item xs>
                {/* <Link href="#" variant="body2">
                  Forgot password?
                </Link> */}
              </Grid>
              <Grid item>
                <Link to='/register' variant='body2'>
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    );
  }
}
