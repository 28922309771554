import React from "react";
import "./post.css";
import { Avatar } from "@material-ui/core";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import RepeatIcon from "@material-ui/icons/Repeat";
//import FavouriteBorderIcon from "@material-ui/icons/Favorite";
import PublishIcon from "@material-ui/icons/Publish";
import { Link } from "react-router-dom";
import Follow from "./components/Follow";
const Post = ({
  id,
  username,
  title,
  details,
  image,
  avatar,
  created_at,
  slug,
  // comments_with_replies_count,
  check_if_follows_count,
  followers_count,
  noFooter,
}) => {
  return (
    <div className='post'>
      <div className='post__avatar'>
        <Avatar
          src={
            avatar
              ? avatar
              : " https://res.cloudinary.com/surazz501/image/upload/v1613750188/eov702nqhrih2bezb3hv.png"
          }
        />
      </div>

      <div className='post__body'>
        <Link
          to={`/post/${slug}`}
          style={{ color: "inherit", textDecoration: "inherit" }}>
          <div className='post__header'>
            <div className='post__headerText'>
              <h3>
                {username}
                <span className='post__headerSpecial'>
                  <VerifiedUserIcon className='post__badge' />
                </span>
              </h3>
              <span className='header__timestamp'>{created_at}</span>
            </div>
            <div className='post__headerDescription'>
              <p>{title}</p>
              <p>{details}</p>
            </div>
          </div>
          <div className='image__container'>
            {image?.map((image, index) => {
              const { path } = image;

              return (
                <img key={index} className='post__image' alt='' src={path} />
              );
              //return <></>;
            })}
          </div>
        </Link>
        {typeof noFooter == "undefined" && (
          <div className='post__footer'>
            <ChatBubbleOutlineIcon fontSize='small' />
            <RepeatIcon fontSize='small' />
            <Follow
              id={id}
              follow={check_if_follows_count}
              followers_count={followers_count}
            />
            <PublishIcon fontSize='small' />
          </div>
        )}
      </div>
    </div>
  );
};
export default Post;
