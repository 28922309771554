class Errors{
    errors = {};

    getErrors(){
        return this.errors;
    }
    setErrors(response){

           return this.errors = response.data.errors;
    }

    getKey(key){
        return (this.errors[key] !== undefined ) ? this.errors[key] : null;
    }
    reset(){
        return this.errors = {};
    }
}
export default new Errors();