import React from "react";
import Sidebar from "../Sidebar";
import Feed from "../Feed";
import Widgets from "../Widgets";


function Layout(props) {

  return (
    <>
      <div className="app">
        <Sidebar {...props} />
        <Feed {...props}/>
        <Widgets />
      </div>
    </>
  );
}

export default Layout;
