import React from 'react'
import Layout from './Layout';
import auth from './Auth/auth';
import ProfileCard from './ProfileCard';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import './profile.css';
import MyPosts from './MyPosts';
import FollowedPosts from './FollowedPosts';
function Profile(props) {
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
       setTabValue(newValue);
  };

    return (
         <Layout headerTitle={auth.getUserName()} {...props}>
             <ProfileCard username={auth.getUserName()}/>
             <Paper square>
                  <Tabs
        value={tabValue}
        indicatorColor="primary"
        textColor="primary"
         onChange={handleChange}
      >
           
              <Tab label="My Posts" />
          
              <Tab label="Followed Posts" />
           
      
      </Tabs>
        </Paper>
        <div>
          {tabValue === 0 && <MyPosts />}
          { tabValue === 1 && <FollowedPosts />}
                  
        </div>
             </Layout>
    )
}

export default Profile
