import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "./components/config";
import auth from "./components/Auth/auth";
import Layout from "./components/Layout";
import IndividualPost from "./components/IndividualPost";
import "./postdetails.css";
function PostDetails(props) {
  // const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState([]);
  const headers = auth.getHeaders();

  let { slug } = useParams();

  const fetchPostDetail = () => {
    // setIsLoading(true);
    axios
      .post(`${API_URL}/get-post-by-slug/${slug}`, {}, { headers })
      .then(function (response) {
        //  setIsLoading(false)
        console.log(response.data.data);
        setDetail([response.data.data]);
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  useEffect(() => {
    fetchPostDetail();
  }, []);

  return (
    <Layout headerTitle='Detail' {...props}>
      {detail.map((detail, index) => {
        const {
          id,
          user_image,
          user_name,
          title,
          details,
          images,
          verified,
          formatted_date,
          all_comments,
          comments_with_replies_count,
          check_if_follows_count,
          followers_count,
        } = detail;
        return (
          <IndividualPost
            id={id}
            key={index}
            avatar={user_image}
            username={user_name}
            title={title}
            details={details}
            image={images}
            verified={verified}
            created_at={formatted_date}
            slug={slug}
            comments={all_comments}
            comments_count={comments_with_replies_count}
            follow={check_if_follows_count}
            followers_count={followers_count}
          />
        );
      })}
    </Layout>
  );
}

export default PostDetails;
