import React, { useState } from "react";
import FavouriteBorderIcon from "@material-ui/icons/Favorite";
import Tooltip from "@material-ui/core/Tooltip";
import auth from "../components/Auth/auth";
import "./follow.css";
import axios from "axios";
import { API_URL } from "./config";
import { useSnackbar } from "notistack";
import Badge from "@material-ui/core/Badge";

function Follow({ id, follow, followers_count, updateFollowersCount }) {
  // console.log("post idis " + id);
  const [following, setFollowing] = useState(follow);
  const [count, updateCount] = useState(followers_count);

  const headers = auth.getHeaders();
  const { enqueueSnackbar } = useSnackbar();

  const handleFollow = (follow) => {
    const follow_url = follow === 1 ? "unfollow" : "follow";
    setFollowing(follow === 1 ? 0 : 1);
    axios
      .post(
        `${API_URL}/post/${follow_url}`,
        {
          post_id: id,
        },
        { headers }
      )
      .then(function (response) {
        enqueueSnackbar(
          `${
            follow_url === "unfollow"
              ? "Post unfollowed successfully!!"
              : "Post followed successfully!!"
          }`,
          {
            variant: "success",
          }
        );
        if (typeof followers_count != "undefined") {
          console.log("ya pugiyo");
          if (follow_url === "unfollow") {
            updateCount(count - 1);
            updateFollowersCount("subtract");
            // console.log(typeof followers_count);
          } else {
            updateCount(count + 1);
            updateFollowersCount("add");
          }
        } else {
          console.log("ya pugiyena");
        }
      })
      .catch(function (error) {});
  };
  return (
    <>
      {typeof followers_count == "undefined" && (
        <Tooltip title={`${following === 1 ? "Unfollow" : "Follow"}`}>
          <FavouriteBorderIcon
            fontSize='small'
            className={`${
              following === 1 ? "follow-icon red-follow" : "follow-icon"
            }`}
            onClick={() => handleFollow(following)}
          />
        </Tooltip>
      )}

      {typeof followers_count !== "undefined" && (
        <Tooltip title={`${following === 1 ? "Unfollow" : "Follow"}`}>
          <Badge color='secondary' badgeContent={count} /*showZero*/>
            <FavouriteBorderIcon
              fontSize='small'
              className={`${
                following === 1 ? "follow-icon red-follow" : "follow-icon"
              }`}
              onClick={() => handleFollow(following)}
            />
          </Badge>
        </Tooltip>
      )}
    </>
  );
}

export default Follow;
