import React from "react";
import "./sidebarOption.css";
import {Link, useLocation} from 'react-router-dom';


function SidebarOption({ active, text, Icon,to }) {
  const isActive = useLocation().pathname === to

;  return (
    <Link to={to} className="sidebar-link">                  
    <div className={`sidebarOption ${isActive && "sidebarOption--active"}`}>
      <Icon />
      <h2>{text}</h2>
    </div>
    </Link>
  );
}

export default SidebarOption;
