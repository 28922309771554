import React, { useState, useEffect } from "react";
import { API_URL } from "./config";
import Post from "../Post";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import auth from "./Auth/auth";

function MyPosts(props) {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const headers = auth.getHeaders();
  const fetchPosts = () => {
    setIsLoading(true);
    axios
      .post(
        `${API_URL}/my-posts`,
        {
          offset: posts.length,
        },
        { headers }
      )
      .then(function (response) {
        // console.log(response);
        setIsLoading(false);
        setPosts([...posts, ...response.data.data]);
      })
      .catch(function (error) {
        //console.log(error);
      });
  };
  useEffect(() => {
    fetchPosts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadMore = () => {
    fetchPosts();
  };
  return (
    <div style={{ marginTop: "10px" }}>
      {posts.map((post, index) => {
        const {
          id,
          user_image,
          user_name,
          title,
          details,
          images,
          verified,
          created_at,
          slug,
          check_if_follows_count,
          followers_count,
        } = post;

        return (
          <Post
            id={id}
            key={index}
            avatar={user_image}
            username={user_name}
            title={title}
            details={details}
            image={images}
            verified={verified}
            created_at={created_at}
            slug={slug}
            check_if_follows_count={check_if_follows_count}
            followers_count={followers_count}
          />
        );
      })}
      <div className='spinner-loading'>{isLoading && <CircularProgress />}</div>
      <div className='load-more'>
        <button onClick={loadMore}> Load More</button>
      </div>
    </div>
  );
}

export default MyPosts;
