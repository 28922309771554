class Auth {
  constructor() {
    this.authenticated = false;
  }

  login = (cb, user_details) => {
    //console.log(user_details);
    localStorage.setItem("token", user_details.data.api_token);
    localStorage.setItem("user_name", user_details.data.name);

    localStorage.setItem(
      "profile_img",
      user_details.data.profile_img
        ? user_details.data.profile_img
        : "https://res.cloudinary.com/surazz501/image/upload/v1613750188/eov702nqhrih2bezb3hv.png"
    );
    //  localStorage.setItem("cover_img", user_details.data.cover_img);
    this.authenticated = true;
    cb();
  };

  logout = (cb) => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_name");
    localStorage.removeItem("profile_img");
    // localStorage.removeItem("cover_img");

    this.authenticated = false;
    cb();
  };
  getHeaders() {
    return {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
  }
  isAuthenticated = () => {
    const token = localStorage.getItem("token");
    if (token) {
      return (this.authenticated = true);
    } else {
      return false;
    }
  };

  getUserName = () => {
    const userName = localStorage.getItem("user_name");
    return userName;
  };
  getProfileImg = () => {
    const profile_img = localStorage.getItem("profile_img");
    if (profile_img == null) {
      return;
    }
    return profile_img;
  };
  setProfileImg = (profile_img_url) => {
    const profile_img = localStorage.setItem("profile_img", profile_img_url);
    return profile_img;
  };
  getCoverImg = () => {
    const cover_img = localStorage.getItem("cover_img");
    return cover_img;
  };
}
export default new Auth();
