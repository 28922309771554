import React from "react";
import { Switch, Route } from "react-router-dom";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import AuthMiddleware from "./components/AuthMiddleware";
import HomePage from "./components/HomePage";
import Profile from "./components/Profile";
import "./App.css";
import { SnackbarProvider } from "notistack";
import PostDetails from "./PostDetails";
import "react-image-lightbox/style.css"; //import only once in whole application

function App() {
  return (
    <>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <Switch>
          <Route path='/' exact component={Login}></Route>
          <Route path='/login' exact component={Login}></Route>
          <Route path='/register' exact component={Register}></Route>
          <AuthMiddleware path='/home' exact component={HomePage} />
          <AuthMiddleware path='/post/:slug' exact component={PostDetails} />
          <AuthMiddleware path='/profile' exact component={Profile} />
          <Route path='*'>
            <h1>404 not found </h1>
          </Route>
        </Switch>
      </SnackbarProvider>
    </>
  );
}

export default App;
